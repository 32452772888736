/* jshint ignore:start */
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/* ----------------------------------------------------------------
 Functienaam
 ---------------------------------------------------------------- */
//
// function formSteps() {
//     $('.form-step header').on('click', function(){
//         $(this).siblings('.form-step__form').show(function() {}, $(this).parents('.form-step').addClass('active-step'));
//     });
// }

/* ========================================================================
 INIT SCRIPTS
 * ======================================================================== */
(
    function ($) {

        // Use this variable to set up the common and page specific functions. If you
        // rename this variable, you will also need to rename the namespace below.
        var Sage = {
            // All pages
            'common': {
                init: function () {
                    $('.form-step header').on('click', function () {
                        $(this).parents('.form-step').find('.form-step__form').slideToggle();
                        $(this).parents('.form-step').find('header').toggleClass('active');
                        $(this).parents('.form-step').toggleClass('active-step');
                    });
                    // JavaScript to be fired on all pages
                    //mobileMenu();
                },
                finalize: function () {
                    // JavaScript to be fired on all pages, after page specific JS is fired
                }
            }
        };

        // The routing fires all common scripts, followed by the page specific scripts.
        // Add additional events for more control over timing e.g. a finalize event
        var UTIL = {
            fire: function (func, funcname, args) {
                var fire;
                var namespace = Sage;
                funcname =
                    (
                        funcname === undefined
                    ) ? 'init' : funcname;
                fire = func !== '';
                fire = fire && namespace[func];
                fire = fire && typeof namespace[func][funcname] === 'function';

                if (fire) {
                    namespace[func][funcname](args);
                }
            },
            loadEvents: function () {
                // Fire common init JS
                UTIL.fire('common');

                // Fire page-specific init JS, and then finalize JS
                $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, 'finalize');
                });

                // Fire common finalize JS
                UTIL.fire('common', 'finalize');
            },

            checkForms: function () {
                var formNoticeField = document.querySelector('.wpcf7-response-output');

                var button = formNoticeField.parentElement.querySelector('button span'),
                    buttonText = button.innerHTML;

                var observer = new MutationObserver(function (mutations) {
                    mutations.forEach(function (mutation) {
                        button.innerHTML = buttonText;
                        if (!formNoticeField.innerHTML) {
                            button.innerHTML = 'Uw melding wordt verzonden...';
                        }
                    });
                });

                observer.observe(formNoticeField, { attributes: true, childList: true, characterData: true });
            }
        };

        // Load Events
        $(document).ready(UTIL.loadEvents);
        $(document).ready(UTIL.checkForms);

    }
)(jQuery); // Fully reference jQuery after this point.
/* jshint ignore:end */
